import * as type from '../types';


export function fetchSearchResults(categoryID = 0,query='') {
  return {
    type: type.SERACH_REQUESTED,
    categoryID: categoryID,
    query: query
  }
}

export function updateDetails(details = null) {
  return {
    type: type.SERACH_DETAILS,
    payload: details
  }
}

export function updateNearByList(data = []) {
  return {
    type: type.SERACH_DETAILS,
    payload: [...data]
  }
}