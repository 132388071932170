import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../types';
const apiUrl = `${process.env.REACT_APP_API_URL}/search?category=`;
function getApi(category,query) {
  return fetch(apiUrl+category+'&query='+query, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',

      }
  }).then(response => response.json())
    .catch((error) => {throw error})
}

function* fetchResidence(action) {
   try {
       console.log(action);
      const results = yield call(getApi,action.categoryID,action.query);
      yield put({type:  type.SEARCH_SUCCESS, results: results});
   } catch (e) {
      yield put({type: type.SEARCH_FAILED, message: e.message});
   }
}

function* searchSaga() {
   yield takeEvery(type.SERACH_REQUESTED, fetchResidence);
}

export default searchSaga;