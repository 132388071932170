
import './App.css';
import MapPage  from './pages/Map';

function App() {
  return (
    
      <MapPage />
  );
}

export default App;
