import * as type from '../types'

const initialState = {
    results: [],
    loading: false,
    error: null,
    categoryID: 0,
    nearbyList:[],
    details: null,
    query: '',
  }
  
  export default function users(state = initialState, action) {
    switch (action.type) {
      case type.SERACH_REQUESTED:
        return {
          ...state,
          loading: true,
          categoryID: action.categoryID,
          query: action.query
        }
      case type.SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          results: action.results
        }
      case type.SEARCH_FAILED:
        return {
          ...state,
          loading: false,
          error: action.message,
        }
        case type.SEARCH_DETAILS_NEARBY:
        return {
          ...state,
          loading: false,
          nearbyList: action.payload,
        }
        case type.SERACH_DETAILS:
        return {
          ...state,
          loading: false,
          details: action.payload,
        }
      default:
        return state
    }
  }