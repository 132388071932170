import react,{useState, useEffect, useRef} from 'react'
import { Footer, NearByItems } from '../components/footers';
import {Overlay, SearchBar, SearchResults,SearchDetails} from '../components/overlays/Index'
import MapContainer from '../containers/Map';
const MapPage =()=>{
    const mapContainer = useRef(null)
    const [center, setCenter] = useState([144.838926148844,13.5149514637189 ]);
    const [zoom, setZoom] = useState(10);
    
    return  <MapContainer center={center} mapRef={mapContainer} zoom={zoom}>
         <div className="mapbox-map" ref={mapContainer}>

         </div>
                <Overlay>
                    <SearchBar />
                    <SearchResults />
                    <SearchDetails />
                </Overlay>
                <Footer>
                    <NearByItems />
                </Footer>
    </MapContainer>
}

export default MapPage