import { combineReducers } from 'redux'
import users from './users';
import search from './search'
import UIManager from './UIManager'

const rootReducer = combineReducers({
  users,
  search ,
  UIManager
});

export default rootReducer;