import mapboxgl from 'mapbox-gl'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import extent from 'turf-extent'
import MapContext from '../../context/MapContext'
import { updateDetails } from '../../redux/actions/search'
import { showNearByPanel } from '../../redux/actions/UIManager'

const SearchDetails = ({}) => {

  const {details} = useSelector((state)=>state.search)
  const { map } = useContext(MapContext)
  const [show,setShow] = useState(false)
  
  

  const dispatch = useDispatch()
  useEffect(()=>{
    if(details === null) {
      setShow(false)
    } else {
      setShow(true)
    }
  },[details])
  
  const {bus_id,category,business_name, name,address,village,email,latitude,longitude,product,phone,facebook,instagram,twitter,youtube,whatsapp,googleaddress,website} = details || {};

    return <div className="search-results" style={{display:(show?'block':'none')}}> 

           <img className="img-responsive" style={{height:'120px',margin:'0 auto',display:'block'}} src={`https://guma-bus-registry.s3.us-west-1.amazonaws.com/business/logo/${bus_id}.png`} alt="Card image cap"/>
            <div className="card-body">
              <h5 className="card-title" style={{margin:'0 auto',textAlign:'center'}}>{business_name}</h5><br/>
              <p className="card-text"><i className="fas fa-info-circle"></i>&nbsp;{product}</p>
              <p className="card-text"><i className="fas fa-address-card"></i>&nbsp;{address}</p>
            </div>
            <ul className="list-group list-group-flush">
            <li className="list-group-item"><i className="fas fa-user"></i>&nbsp;{name}</li>
              <li className="list-group-item"><i className="fas fa-envelope-square"></i>&nbsp;{email}</li>
              <li className="list-group-item"><i className="fas fa-phone-volume"></i>&nbsp;{phone}</li>
              <li className="list-group-item"><i className="fas fa-map-marker-alt"></i>&nbsp;{latitude},{longitude}</li>
              <li>
                 
                  {(facebook!=null && facebook.trim() !='') &&
                  <a className="btn btn-lg facebook" target="_blank" href={facebook}><i className="fab fa-facebook"></i></a>
                  }
                  {(twitter !=null && twitter.trim() !='')&&
                    <a className="btn btn-lg twitter"  target="_blank" href={twitter}><i className="fab fa-twitter"></i></a>
                  }
                  {(youtube != null && youtube.trim() !='') && 
                    <a className="btn btn-lg youtube" target="_blank" href={youtube}><i className="fab fa-youtube"></i></a>
                  }
                  {(instagram != null && instagram.trim() !='')  &&
                    <a className="btn btn-lg instagram" target="_blank" href={instagram}><i className="fab fa-instagram"></i></a>
                  }
                  {(whatsapp != null && whatsapp.trim() !='') &&
                  <a  className="btn btn-lg whatsapp" target="_blank" href={'https://api.whatsapp.com/send?phone='+whatsapp}><i className="fab fa-whatsapp"></i></a>
                  }
                  {(googleaddress != null && googleaddress.trim() !='') &&
                  <a className="btn btn-lg google"  target="_blank" href={googleaddress}><i className="fab fa-google"></i></a>
                  }
                  
              </li>
            </ul>
            <div className="card-footer">
                {(website != null && website.trim() !='') &&
                  <a className="card-link" target="_blank" href={website}>Website</a>
                  }
              <a href={`http://guma.nesiadev.com/business/${bus_id}`} target="_blank" className="card-link">More Info</a>
              <a href="#" className="card-link" onClick={()=>{dispatch(updateDetails(null))}}>Back to result</a>
            </div>
          </div>    
}

export default SearchDetails