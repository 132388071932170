export const GET_USERS = 'GET_USERS';
export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const SERACH_REQUESTED = 'GET_SERACH_REQUESTED';
export const SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const SEARCH_FAILED = 'GET_SEARCH_FAILED';
export const SERACH_DETAILS = 'SERACH_DETAILS';
export const SEARCH_DETAILS_NEARBY='SEARCH_DETAILS_NEARBY';


export const SHOW_SEARCH_NEARBY_PANEL = 'SHOW_SEARCH_NEARBY_PANEL';
export const SHOW_SEARCH_RESULT_PANEL = 'SHOW_SEARCH_RESULT_PANEL';
