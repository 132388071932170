import React, { useRef, useState, useEffect } from "react"
import mapboxgl from "mapbox-gl";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import MapContext from "../context/MapContext"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const mapStyles = [
  {
      title: "Outdoor",
      uri:"mapbox://styles/munnabhakta/ckq3mhj8s0gw718qcdob0ikm8"
  },
  {
      title: "Dark",
      uri:"mapbox://styles/munnabhakta/ckq3mpjju4b0b17n6qys5pbu3"
  },
  {
      title: "Satellite",
      uri:"mapbox://styles/munnabhakta/ckq2dmu4y1ql917p9c7232odt"
  },
  {
    title: 'Basic',
    uri: 'mapbox://styles/munnabhakta/ck9zcmwgc1ma31iph4a849nl9'

  }
];
const Map = ({ children, zoom, center,mapRef }) => {
  const [map, setMap] = useState(null);
  useEffect(() => {
    if (map) return; // initialize map only once
    const map2D = new mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/munnabhakta/ck9zcmwgc1ma31iph4a849nl9',
      center: center,
      zoom: zoom
    });
    map2D.addControl(new MapboxStyleSwitcherControl(mapStyles, "Basic"))
    map2D.addControl(new mapboxgl.NavigationControl())
    map2D.addControl(new mapboxgl.FullscreenControl())
    setMap(map2D);
  });

  return (
    <MapContext.Provider value={{ map }}>
        {children}
      </MapContext.Provider>
  )
}
export default Map;