import React, { Fragment, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';

import { fetchSearchResults } from '../../redux/actions/search';

const SearchBar = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query,setQuery] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const dispatch = useDispatch();

  const handleSearch = (query) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/autocomplete?query=${query}`)
      .then((resp) => resp.json())
      .then((data) => {
        if(data != undefined) {
          const options = data.map((i) => ({
            id: i.id,
            text: i.category,
          }));
          setOptions(options);
        }
        

        
        setIsLoading(false);
      });
  };
  const onKeyDown = useCallback(
    (e) => {
      // Check whether the 'enter' key was pressed, and also make sure that
      // no menu items are highlighted.
      
      if (e.keyCode === 13 && activeIndex === -1) {
        dispatch(fetchSearchResults(0,e.target.defaultValue))
      }
    },
    [activeIndex]
  );

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;
  
  
    return <div className="searchbar"> 
           <div className="input-group">
           <AsyncTypeahead
           
      filterBy={filterBy}
      onKeyDown={onKeyDown}
      id="searchBox"
      isLoading={isLoading}
      labelKey="text"
      minLength={3}
      onSearch={handleSearch}
      options={options}
      onChange={(item)=>{  
        if(item == undefined || item.length == 0) 
          return true
        console.log(item)
      dispatch(fetchSearchResults(item[0].id,query))
    }}
      placeholder="Type your choice here..."
      renderMenuItemChildren={(option, props) => (
        <Fragment className="list-inline-item">
          <span>{option.text}</span>
        </Fragment>
      )}
    > {(state) => {
      // Passing a child render function to the component exposes partial
      // internal state, including the index of the highlighted menu item.
      setActiveIndex(state.activeIndex);
    }}</AsyncTypeahead>
              {/* <input type="text" className="form-control" placeholder="Type your choice here..." aria-label="Type your choice here..." aria-describedby="basic-addon2"/> */}
              <div className="input-group-append">
                <button className="btn btn-outline-success"  type="button"><i className="fas fa-search"></i></button>
              </div> 
            </div>
          </div>    
}

export default SearchBar