import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
import rootReducer from './reducers/index.js';
import rootSaga from './sagas/index';

const persistConfig = {
  key: 'root',
  storage,
}
// const persistedReducer = persistReducer(persistConfig, rootReducer)
 
const sagaMiddleware = createSagaMiddleware();
const store = compose(
  applyMiddleware(sagaMiddleware),
)(createStore)(rootReducer);
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export {store, persistor};