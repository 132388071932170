import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useSelector } from 'react-redux';

const NearByItems = ({}) => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
  const searchItems = [
    { title: 'Crust Pizzeria Napoletana - Guam',location:'356 E. Marine Corps Drive East, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipP4g7zwNEoRFz2TSvG4hQiymkVvOEvoNAvvPI0=w408-h544-k-no'
    },
    { title: 'Chung Suk Gol (Korean Restaurant)',location:'East West business center Harmon, 96912, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNn3YuGM83ip-dqgvpy8Dmh8POHTIArRKDRw7I5=w408-h306-k-no'
    },
    { title: 'Jamaican Grill',location:'FQH2+6M7, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipMom_7a-4O2w6HysLU5zxJ8qMLUNWCOmHu-UNbH=w408-h306-k-no'
    },
    { title: 'Chamorro Island BBQ',location:'238 Marine Corps Drive Hagåtña Guam, 96910, 238 Marine Corps Dr, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNbQ7RhAMrFkJcNYVVj3ao4EgPxHVqPe_r9k6Lk=w426-h240-k-no'
    },
    { title: 'Jamaican Grill',location:'FQH2+6M7, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipMom_7a-4O2w6HysLU5zxJ8qMLUNWCOmHu-UNbH=w408-h306-k-no'
  },
  { title: 'Chamorro Island BBQ',location:'238 Marine Corps Drive Hagåtña Guam, 96910, 238 Marine Corps Dr, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNbQ7RhAMrFkJcNYVVj3ao4EgPxHVqPe_r9k6Lk=w426-h240-k-no'
  },{ title: 'Chamorro Island BBQ',location:'238 Marine Corps Drive Hagåtña Guam, 96910, 238 Marine Corps Dr, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNbQ7RhAMrFkJcNYVVj3ao4EgPxHVqPe_r9k6Lk=w426-h240-k-no'
},
{ title: 'Jamaican Grill',location:'FQH2+6M7, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipMom_7a-4O2w6HysLU5zxJ8qMLUNWCOmHu-UNbH=w408-h306-k-no'
},
{ title: 'Chamorro Island BBQ',location:'238 Marine Corps Drive Hagåtña Guam, 96910, 238 Marine Corps Dr, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNbQ7RhAMrFkJcNYVVj3ao4EgPxHVqPe_r9k6Lk=w426-h240-k-no'
},{ title: 'Chamorro Island BBQ',location:'238 Marine Corps Drive Hagåtña Guam, 96910, 238 Marine Corps Dr, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNbQ7RhAMrFkJcNYVVj3ao4EgPxHVqPe_r9k6Lk=w426-h240-k-no'
},
{ title: 'Jamaican Grill',location:'FQH2+6M7, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipMom_7a-4O2w6HysLU5zxJ8qMLUNWCOmHu-UNbH=w408-h306-k-no'
},
{ title: 'Chamorro Island BBQ',location:'238 Marine Corps Drive Hagåtña Guam, 96910, 238 Marine Corps Dr, Hagåtña, 96910, Guam',image: 'https://lh5.googleusercontent.com/p/AF1QipNbQ7RhAMrFkJcNYVVj3ao4EgPxHVqPe_r9k6Lk=w426-h240-k-no'
},
  ]
  const {showNearbyPanel} = useSelector((state)=>state.UIManager)
  const renderResults=()=>{
    return searchItems.map(item=>{
      return <div class="card border-secondary mb3" style={{width:'200px'}}>
      <img class="card-img-top img-responsive rounded" style={{height:'150px'}} src={item.image} alt="Card image cap"/>
      <div class="card-body">
        <p class="card-text">{item.title}</p>
      </div>
    </div>
    }) 
  }
    return <div className="nearby-results" style={{display:(showNearbyPanel?'block':'none')}}> 
           <Carousel responsive={responsive} centerMode={false}>
            {renderResults()}
            </Carousel>
          </div>    
}

export default NearByItems