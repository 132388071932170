import * as type from '../types'

const initialState = {
    showSearchResultPanel: false,
    showNearbyPanel: false,
  }
  
  export default function UIManager(state = initialState, action) {
    switch (action.type) {
      case type.SHOW_SEARCH_NEARBY_PANEL:
        return {
          ...state,
          showNearbyPanel: action.show,
        }
      case type.SHOW_SEARCH_RESULT_PANEL:
        return {
          ...state,
          showSearchResultPanel: action.show
        }
      default:
        return state
    }
  }