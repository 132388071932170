import mapboxgl from 'mapbox-gl'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import extent from 'turf-extent'
import MapContext from '../../context/MapContext'
import { showNearByPanel } from '../../redux/actions/UIManager'
import { updateDetails } from '../../redux/actions/search'

const SearchResults = ({}) => {

  const {results, details} = useSelector((state)=>state.search)
  const { map } = useContext(MapContext)
  

  const dispatch = useDispatch()
  const {showSearchResultPanel} = useSelector((state)=>state.UIManager)
  const [features,setFeatures] = useState([])
  const [show,setShow] = useState(false)
  useEffect(()=>{
   setFeatures( results.map(item=>{
      return {
        type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.longitude,item.latitude]
      },
      properties: item
      }
    }))
  },[results])
  useEffect(()=>{
    setShow(details?false:true);
  },[details])
  useEffect(()=>{
   //const input = turf.featureCollection(features);
   const orgbox= extent({
    type: 'FeatureCollection',
    features:features});
    features.forEach(function(marker) {

      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage=`url(https://guma-bus-registry.s3.us-west-1.amazonaws.com/business/logo/${marker.properties.bus_id}.png)`
      el.onclick=function(){
          dispatch(updateDetails(marker.properties))
      }
      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(map);
    });
    if(map != null && orgbox[0] !== Infinity) {
      console.log(orgbox);
      map.fitBounds(orgbox, {padding: 20});
    }
  },[features])
  const zoomToMap=(feature)=> {

    map.flyTo({
      // These options control the ending camera position: centered at
      // the target, at zoom level 9, and north up.
      center: [feature.longitude,feature.latitude],
      zoom: 18,
      bearing: 0,
       
      // These options control the flight curve, making it move
      // slowly and zoom out almost completely before starting
      // to pan.
      speed: 2, // make the flying slow
      curve: 1, // change the speed at which it zooms out
       
      // This can be any easing function: it takes a number between
      // 0 and 1 and returns another number between 0 and 1.
      easing: (t) => t,
       
      // this animation is considered essential with respect to prefers-reduced-motion
      essential: true
      });
  }
  const renderResults=()=>{
    return results.map(item=>{
      return <div className="card w-100" style={{cursor:'pointer'}}> 
      <div className="card-body" onClick={(e)=>{zoomToMap(item); dispatch(updateDetails(item)) }}>
        <div className="row">
        <div className="col-9">
        <h6 className="card-title">{item.business_name}</h6>
        <small className="card-text help"><i className="fas fa-address-card"></i>&nbsp;{item.address}</small><br/>
        <small><i className="fas fa-phone-volume"></i>&nbsp;{item.phone}</small>
        {/* <small><i className="fas fa-envelope-square"></i>&nbsp;{item.email}</small> */}
        </div>
        <div className="col-3">
          <img src={`https://guma-bus-registry.s3.us-west-1.amazonaws.com/business/logo/${item.bus_id}.png`} className="rounded img-responsive" width="50%" />
        </div>
      </div>
      </div>
    </div>
    }) 
  }
    return <div className="search-results" style={{display:(show?'block':'none')}}> 
           <div className="cards">
            {renderResults()}

            </div>
          </div>    
}

export default SearchResults